<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
  <!-- <TheLogin v-else @TheLogin::loginResult="handleLoginResult"/> -->

</template>

<script>
import TheLogin from './components/TheLogin'
export default {
  name: 'app',
  components: {
    TheLogin
  },
  data() {
    return {
      userIsLoggedIn: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.userIsLoggedIn
    }
  },
  methods: {
    handleLoginResult({loginResult}) {
      this.userIsLoggedIn = loginResult
    }
  }
}
</script>
