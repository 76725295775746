<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <router-link class="navbar-brand" to="/landing">Zals Notes</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
          <router-link class="nav-link" to="/landing">Home</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" to="/regis">Tambah Data User</router-link>
        </li> -->
        <li class="nav-item">
          <router-link class="nav-link" to="/about">About</router-link>
          <!-- <a class="nav-link" href="about">About</a> -->
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Ayah
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'bapak' }}">Akun</router-link>
            <router-link class="dropdown-item" to="/ayah/notes">Notes</router-link>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Mama
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'mama' }}">Akun</router-link>
            <router-link class="dropdown-item" to="/mama/notes">Notes</router-link>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Rizal
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'rizal' }}">Akun</router-link>
            <router-link class="dropdown-item" to="/rizal/notes">Notes</router-link>
          </ul>
        </li> -->
      </ul>
      <div class="d-flex">
       <button v-on:click="logout">Logout</button>
      </div>
    </div>
  </div>
</nav>
</template>
<script>
import { authnya } from '../firebase.js'

  export default {
  name: 'Header',
  methods: {
    logout() {
      authnya.signOut()
      // this.$emit('TheLogin::loginResult', {loginResult: false})
      this.$router.push('/')
    }
  }
}
</script>