import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'


// firebase init - add your own config here
const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    databaseURL: process.env.VUE_APP_databaseURL,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId
}
firebase.initializeApp(firebaseConfig)

// utils
const firestorenya = firebase.firestore()
let db = firebase.database();
const userRef = db.ref('users');
let authnya = firebase.auth();
let fb = firebase;

export {
    firestorenya,
    userRef,
    db,
    authnya,
    fb
}