import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Akun from '../views/Akun.vue'
import Notes from '../views/Notes.vue'
import Login from '../components/TheLogin.vue'
import Regis from '../components/Regis.vue'
import { authnya } from '../firebase.js'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/regis',
    name: 'Regis',
    component: Regis,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/landing',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/:orangnya/akun',
    name: 'Akun',
    component: Akun,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/:orangnya/notes',
    name: 'Notes',
    component: Notes,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      authRequired: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
      if (authnya.currentUser) {
          next();
      } else {
          alert('Login disek broo');
          next({
              path: '/',
          });
      }
  } else {
      next();
  }
});

export default router
