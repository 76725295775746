<template>
<Header />
    <div class="card container">
        <div class="card-header">
          Tambah Data Akun {{ orangnya }}
        </div>
            <div class="card-body">
            <div class="card-text">
            <div class="card-text">
                <form @submit.prevent="add">
                <div class="form-group row">
                <label for="name" class="col-sm-2 col-form-label" >Jenis Akun:</label>
                <div class="col-sm-10">
                    <input autocomplete="off" type="text" id="jenis_akun" class="form-control" v-model="dataAkun.jenis_akun">
                </div>
                </div>
                <div class="form-group row">
                <label for="city" class="col-sm-2 col-form-label">Akun:</label>
                <div class="col-sm-10">
                    <textarea id="datanya" class="form-control" rows="7"  v-model="dataAkun.datanya"/>
                </div>
                </div>
                <br>
                <button type="submit" class="btn btn-primary float-right">tambah data</button>        
            </form>
            </div>
            </div>
        </div>
      </div>
      <div class="container">
          <br><br><br>
            <div class="card">
            <h4 class="card-header">
                Akun List
            </h4>
            <div class="card-body">
                <p class="card-text table-responsive">
                <table class="table">
                    <thead class="thead-inverse">
                    <tr>
                        <th>Jenis Akun</th>
                        <th>Akun</th>
                        <th>Aksi</th>
                    </tr>
                    </thead>
                    <tbody v-for="dtmhs in datanya" v-bind:key="dtmhs.key">
                    <tr  v-if="!dtmhs.edit">
                        <td>{{ dtmhs.jenis_akun }}</td>
                        <td v-html="dtmhs.datanya"></td>
                        <td><button @click="editMhs(dtmhs.key)" class="btn btn-warning" >
        Edit
        </button><button class="btn btn-danger" @click="removeMhs(dtmhs.key)">Hapus</button></td>
                    </tr>
                    <tr v-else>
                        <td><input type="text" v-model="dtmhs.jenis_akun" autocomplete="off"></td>
                        <td><textarea rows="7" v-model="dtmhs.datanya"/></td>
                        <td><button @click="saveMhs(dtmhs)" class="btn btn-primary" >
        save
        </button><button class="btn btn-danger" @click="cancelMhs(dtmhs.key)">Batal</button></td>
                    </tr>
                    </tbody>
                </table>
                </p>
            </div>
            </div>
      </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import { db } from '../firebase.js'
// let keluargaRef = db.ref('myfamily/bapak/akun')

export default {
  name: 'Akun',
  data () {
      return {
        dataAkun: {
          jenis_akun: '',
          datanya: '',
          edit: false
        },
        // orangnya: '',
        datanya : []
      }
    },
  components: {
    HelloWorld,
    Header
  },
  methods: {
      add(){
        //   console.log(this.dataAkun)
        this.datanya = []
          let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
          keluargaRef.push(this.dataAkun)
          this.dataAkun.jenis_akun = ''
          this.dataAkun.datanya = ''
          Swal.fire({
                        title: 'Sukses',
                        text: 'Sukses menambahkan data Akun',
                        icon: 'success',
                        confirmButtonText: 'Oke'})
      },
      editMhs(key) {
          this.datanya = []
          let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
          keluargaRef.child(key).update({ edit: true});
      },
      cancelMhs(key) {
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
        keluargaRef.child(key).update({ edit: false});
      },
      removeMhs(key) {
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
        keluargaRef.child(key).remove();
      },
      saveMhs(mhsa){
        const key = mhsa.key
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
        keluargaRef.child(key).set({
          jenis_akun: mhsa.jenis_akun,
          datanya: mhsa.datanya,
        edit: false })
        Swal.fire({
                        title: 'Sukses',
                        text: 'Sukses mengubah data Akun',
                        icon: 'success',
                        confirmButtonText: 'Oke'})
      }
  },
  mounted() {
    this.datanya = []
        // this.orangnya = []
    //     this.orangnya = ''
    //  this.$nextTick(() => {
    //                 this.orangnya = ''
    //                 console.log('re-render start')
    //                 this.$nextTick(() => {
    //                     console.log('re-render end')
    //                 })
    //             })

    // this.orangnya = this.$route.params.orangnya
      var dt = db.ref(`myfamily/${this.orangnya}/akun`)
      dt.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
              var dt = childSnapshot.val();
            //   this.key = childSnapshot.key;
              this.datanya.push({key:childSnapshot.key, jenis_akun:dt.jenis_akun, datanya: dt.datanya, edit:dt.edit})
          })
        // this.datanya = snapshot.val();
      });
  },
  beforeUnmount() {
    this.datanya = []
    this.orangnya = ''
    // var dt = db.ref(`myfamily/${this.$route.params.orangnya}/akun`)
    // dt.off('value', (snapshot) => {
    //       snapshot.forEach((childSnapshot) => {
    //           var dt = childSnapshot.val();
    //         //   this.key = childSnapshot.key;
    //           this.datanya.push({key:childSnapshot.key, jenis_akun:dt.jenis_akun, datanya: dt.datanya, edit:dt.edit})
    //       })
    //     // this.datanya = snapshot.val();
    //   });
  },
  computed: {
      orangnya() {
          return this.$route.params.orangnya
      }
  },
}
</script>