<template>
  <div class="home">
    <Header />
    <div class="container">
      <div class="row">
        <div class="col-md-4" style="">
          <div>
            <div class="card" style="">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <img src="/img/bapak.png" class="card-img-top"/>
              <div class="card-body">
                <h5 class="card-title">Ayah</h5>
                <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Silahkan pilih
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'bapak' }}">Akun</router-link>
                    <router-link class="dropdown-item" to="/ayah/notes">Notes</router-link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="">
          <div>
            <div class="card" style="">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <img src="/img/mama.png" class="card-img-top"/>
              <div class="card-body">
                <h5 class="card-title">Mama</h5>
                <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Silahkan pilih
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'mama' }}">Akun</router-link>
                    <router-link class="dropdown-item" to="/mama/notes">Notes</router-link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="">
          <div>
            <div class="card" style="">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <img src="/img/rizal.png" class="card-img-top"/>
              <div class="card-body">
                <h5 class="card-title">Rizal</h5>
                <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Silahkan pilih
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <router-link class="dropdown-item" :to="{ name: 'Akun', params: { orangnya: 'rizal' }}">Akun</router-link>
                    <router-link class="dropdown-item" to="/rizal/notes">Notes</router-link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import { authnya } from '../firebase.js'


// const authListener = authnya.onAuthStateChanged(function(user) {
//     if (!user) { // not logged in
//         alert('you must be logged in to view this. redirecting to the home page')
//         router.push('/login')
//     }
// })

export default {
  name: 'Home',
  components: {
    HelloWorld,
    Header
  }
}
</script>
