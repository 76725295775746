<template>
  <!-- <div class="TheLogin">
    <h1>Login</h1>

    <div>
      <input type="text" name="username" placeholder="Username">
      <br>
      <input type="password" name="password" placeholder="Password">
      <br><br>
      <button @click="logIn">Log in</button>
    </div>
  </div> -->
  <div class="container" style="padding-top:15%">
    <div class="card">
        <div style="margin:15px">
            <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email</label>
            <input type="email" class="form-control" name="email" v-model="input.email" id="exampleInputEmail1" aria-describedby="emailHelp" autocomplete="off">
            <div id="emailHelp" class="form-text">Masukkan email.</div>
        </div>
        <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <input type="password" class="form-control" name="password" v-model="input.password" id="exampleInputPassword1" v-on:keyup.enter="onEnter">
        </div>
        <button type="submit" class="btn btn-primary" @click="logIn">Login</button>
        </div>        
    </div>
  </div>
  
</template>

<script>
import { userRef,db,authnya } from '../firebase.js'
import { getUA } from '../deviceAPI.js'
export default {
  name: 'TheLogin',
  firebase: {
      mahasiswa: userRef
    },
  data() {
      return {
          input: {
              email: "family@afrizalmy.com",
              password: ""
          }
      }
  },
  methods: {
    onEnter: function() {
      //  this.msg = 'on enter event';
      this.logIn();
    },
    logIn() {
        if (this.input.email != "" && this.input.password != "") {    
            authnya.signInWithEmailAndPassword(this.input.email,this.input.password)
            .then(data => {
                this.$router.replace({ name: "Home" });
            })
            .catch(error => {
                Swal.fire({
                        title: 'Gagal Login!',
                        text: error.message,
                        icon: 'error',
                        confirmButtonText: 'Oke'
                    })
                console.log(error.message);
            })
        } else {
            // console.log(userRef.get())
            // alert('Masih Kosong !!!')
            // this.$swal('Hello Vue world!!!');
            Swal.fire({
                title: 'Error!',
                text: 'Isian Masih Kosong !!',
                icon: 'info',
                confirmButtonText: 'Oke'
                })
                   
            // console.log(navigator.userAgent)
        }
    //   this.$emit('TheLogin::loginResult', {loginResult: true})
    }
  }
}
</script>