<template>
<Header />
    <div class="card container">
        <div class="card-header">
          Tambah Data note {{ orangnya }}
        </div>
            <div class="card-body">
            <div class="card-text">
            <div class="card-text">
                <form @submit.prevent="add">
                <div class="form-group row">
                <label for="name" class="col-sm-2 col-form-label" >Jenis Notes:</label>
                <div class="col-sm-10">
                    <input type="text" id="jenis_notes" class="form-control" v-model="datanote.jenis_note" autocomplete="off">
                </div>
                </div>
                <div class="form-group row">
                <label for="city" class="col-sm-2 col-form-label">Note:</label>
                <div class="col-sm-10">
                    <textarea id="datanya" class="form-control" rows="7"  v-model="datanote.datanya"/>
                </div>
                </div>
                <br>
                <button type="submit" class="btn btn-primary float-right">tambah data</button>        
            </form>
            </div>
            </div>
        </div>
      </div>
      <div class="container">
          <br><br><br>
            <div class="card">
            <h4 class="card-header">
                note List
            </h4>
            <div class="card-body">
                <p class="card-text table-responsive">
                <table class="table">
                    <thead class="thead-inverse">
                    <tr>
                        <th>Jenis Note</th>
                        <th>Note</th>
                        <th>Aksi</th>
                    </tr>
                    </thead>
                    <tbody v-for="dtmhs in datanya" v-bind:key="dtmhs.key">
                    <tr  v-if="!dtmhs.edit">
                        <td>{{ dtmhs.jenis_note }}</td>
                        <td v-html="dtmhs.datanya"></td>
                        <td><button @click="editMhs(dtmhs.key)" class="btn btn-warning" >
        Edit
        </button><button class="btn btn-danger" @click="removeMhs(dtmhs.key)">Hapus</button></td>
                    </tr>
                    <tr v-else>
                        <td><input type="text" v-model="dtmhs.jenis_note" autocomplete="off"></td>
                        <td><textarea rows="7" v-model="dtmhs.datanya"/></td>
                        <td><button @click="saveMhs(dtmhs)" class="btn btn-primary" >
        save
        </button><button class="btn btn-danger" @click="cancelMhs(dtmhs.key)">Batal</button></td>
                    </tr>
                    </tbody>
                </table>
                </p>
            </div>
            </div>
      </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import { db } from '../firebase.js'
// let keluargaRef = db.ref('myfamily/bapak/note')

export default {
  name: 'note',
  data () {
      return {
        datanote: {
          jenis_note: '',
          datanya: '',
          edit: false
        },
        // orangnya: '',
        datanya : []
      }
    },
  components: {
    HelloWorld,
    Header
  },
  methods: {
      add(){
        //   console.log(this.datanote)
                  this.datanya = []
          let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/note`)
          keluargaRef.push(this.datanote)
          this.datanote.jenis_note = ''
          this.datanote.datanya = ''
          Swal.fire({
                        title: 'Sukses',
                        text: 'Sukses menambahkan data note',
                        icon: 'success',
                        confirmButtonText: 'Oke'})
      },
      editMhs(key) {
          this.datanya = []
          let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/note`)
          keluargaRef.child(key).update({ edit: true});
      },
      cancelMhs(key) {
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/note`)
        keluargaRef.child(key).update({ edit: false});
      },
      removeMhs(key) {
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/note`)
        keluargaRef.child(key).remove();
      },
      saveMhs(mhsa){
        const key = mhsa.key
        this.datanya = []
        let keluargaRef = db.ref(`myfamily/${this.$route.params.orangnya}/note`)
        keluargaRef.child(key).set({
          jenis_note: mhsa.jenis_note,
          datanya: mhsa.datanya,
        edit: false })
        Swal.fire({
                        title: 'Sukses',
                        text: 'Sukses mengubah data note',
                        icon: 'success',
                        confirmButtonText: 'Oke'})
      }
  },
  mounted() {
    this.datanya = []

      var dt = db.ref(`myfamily/${this.orangnya}/note`)
      dt.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
              var dt = childSnapshot.val();
            //   this.key = childSnapshot.key;
              this.datanya.push({key:childSnapshot.key, jenis_note:dt.jenis_note, datanya: dt.datanya, edit:dt.edit})
          })
        // this.datanya = snapshot.val();
      });
  },
  beforeUnmount() {
    this.datanya = []
    this.orangnya = ''
  },
  computed: {
      orangnya() {
          return this.$route.params.orangnya
      }
  },
}
</script>