import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import 'mdb-vue-ui-kit/css/mdb.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swal from 'sweetalert2/dist/sweetalert2.all.min.js'

import 'sweetalert2/dist/sweetalert2.min.css'
window.Swal = Swal;

createApp(App).use(router,Swal).mount('#app')
